import { Skeleton, notification } from 'antd'
import Form from 'antd/lib/form'
import { TemplateFormSendTest } from 'components/atoms/TemplateFormSendTest'
import { ModalEmpty } from 'components/atoms/modal'
import { ModalSendEmail } from 'components/molecules/templateBuilder'
import { TEMPLATE_TYPE } from 'constants/template'
import { useTemplate } from 'hooks/useTemplate'
import { mapTypeToMedium } from 'pages/templateBuilderList/helpers'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { NotificationServices } from 'services/notification'
import { useResetFormOnCloseModal } from 'utils/modal'

export type CampaignModalSendTestProps = {
  template_id?: number
  onCancel?: (open: boolean) => void
}

export const CampaignModalSendTest: FC<CampaignModalSendTestProps> = (
  props
) => {
  const { template_id, onCancel: onCancelProps } = props
  const [open, setOpen] = useState(!!template_id)
  const [loadingSend, setLoadingSend] = useState(false)
  const { loading, template, setLoading } = useTemplate({ id: template_id })
  const [form] = Form.useForm()

  const template_type = useMemo(
    () => template?.template?.type,
    [template?.template?.type]
  )

  const onCancel = useCallback(() => {
    const newOpen = !open
    setOpen(newOpen)
    onCancelProps?.(newOpen)
    setLoading(false)
    setLoadingSend(false)
  }, [onCancelProps, open, setLoading])

  const onSendEmail = useCallback(
    async (email: string) => {
      if (!template_id) {
        return
      }
      if (template_type === TEMPLATE_TYPE.APP_PUSH) {
        setLoadingSend(true)
        NotificationServices.appPushTestSendEmail({
          email,
          title: template?.title,
          body: template?.push_message
        })
          .then((res) => {
            if (res._statusRes !== 200) {
              throw new Error(JSON.stringify(res))
            }
            notification.success({
              message: 'Send test email is successfully.'
            })
            onCancel()
          })
          .catch(console.log)
          .finally(() => setLoadingSend(false))
      }

      if (template_type === TEMPLATE_TYPE.EMAIL) {
        setLoadingSend(true)
        NotificationServices.campaignSendTest({
          email,
          template_id
        })
          .then((res) => {
            if (res._statusRes !== 200) {
              throw new Error(JSON.stringify(res))
            }
            notification.success({
              message: 'Send test email is successfully.'
            })
            onCancel()
          })
          .catch(console.log)
          .finally(() => setLoadingSend(false))
      }
    },
    [
      onCancel,
      template?.push_message,
      template?.title,
      template_id,
      template_type
    ]
  )

  const onFinish = useCallback(
    (values: any) => {
      if (!values?.template_id || !values?.phone_number) {
        return
      }
      setLoadingSend(true)
      NotificationServices.campaignSendTest({
        template_id: values.template_id,
        phone_number: `+${values?.phone_number?.countryCode}${values?.phone_number?.areaCode}${values?.phone_number?.phoneNumber}`,
        variables: values.variables
      })
        .then((res) => {
          if (res?._statusRes !== 200) {
            notification.error({ message: res._message })
            throw new Error(JSON.stringify(res))
          }
          notification.success({
            message: `Send test ${mapTypeToMedium[template_type]?.label} is successfully.`
          })
          onCancel()
        })
        .catch(console.log)
        .finally(() => setLoadingSend(false))
    },
    [onCancel, template_type]
  )

  useEffect(() => {
    console.log('** CampaignModalSendTest : ', template_id)
    setOpen(!!template_id)
    form.setFieldValue('template_id', template_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template_id])

  useResetFormOnCloseModal({ form, visible: open })

  if ([TEMPLATE_TYPE.APP_PUSH, TEMPLATE_TYPE.EMAIL].includes(template_type)) {
    return (
      <ModalSendEmail
        open={open}
        setOpenSendEmail={onCancel}
        onSendEmail={onSendEmail}
      />
    )
  }

  return (
    <ModalEmpty
      centered
      title={
        <Skeleton
          loading={loading}
          active
          paragraph={{ className: 'hidden' }}
          title={{ className: 'mb-0 !h-[30px] !rounded-lg' }}>
          Send Test {mapTypeToMedium[template_type]?.label}
        </Skeleton>
      }
      cancelText="Cancel"
      okText="Send"
      afterClose={() => {
        form && form.setFieldsValue({})
      }}
      open={open}
      onCancel={onCancel}
      cancelButtonProps={{ disabled: loading || loadingSend }}
      onOk={form.submit}
      okButtonProps={{ loading: loading || loadingSend }}>
      <TemplateFormSendTest
        form={form}
        loading={loading}
        type={template_type}
        template={template}
        onFinish={onFinish}
      />
    </ModalEmpty>
  )
}
