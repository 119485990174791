import { deleteDictionary, getDictionaries } from '.'
import { MenuProps, notification } from 'antd'
import { MainHeader } from 'components/molecules'
import {
  DictionaryFormValue,
  DictionaryTable,
  DictionaryTableState,
  defaultDictionaryTableState,
  formatDictionaryFormValue
} from 'components/molecules/dictionary'
import {
  DictionaryModalCreate,
  DictionaryModalEdit,
  dictionaryModalTitle
} from 'components/molecules/dictionary/DictionaryModal'
import { DictionaryModalConfirmation } from 'components/molecules/dictionary/DictionaryModal/DictionaryModalConfirmation'
import { PERMISSIONS } from 'constants/permission'
import { useLatest } from 'hooks/useLatest'
import { useCallback, useEffect, useState } from 'react'

export const DictionaryPage = () => {
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState<any[]>([])
  const [params, setParams] = useState<DictionaryTableState>(
    defaultDictionaryTableState
  )
  const [create, setCreate] = useState('')
  const [editInfo, setEditInfo] = useState<Partial<DictionaryFormValue>>()
  const [confirmInfo, setConfirmInfo] = useState<Partial<DictionaryFormValue>>()

  const fetchDictionariesFn = useCallback(
    async (value: DictionaryTableState) => {
      setLoading(true)
      const { data, meta } = await getDictionaries(value)
      const newParams: DictionaryTableState = {
        ...value,
        pagination: {
          current: meta.page || value.pagination.current,
          total: meta.total || value.pagination.total,
          pageSize: meta.perPage || value.pagination.pageSize
        }
      }

      setDataSource(data || [])
      setParams(newParams)
      setLoading(false)
    },
    []
  )
  const fetchDictionaries = useLatest(fetchDictionariesFn)

  const handleChange = useCallback(
    (value: DictionaryTableState) => {
      setParams(value)
      fetchDictionaries.current(value)
    },
    [fetchDictionaries]
  )

  const handleCreated = (value: any) => {
    fetchDictionaries.current({
      dictionary_type: value?.category || params.dictionary_type,
      pagination: {
        total: 0,
        current: 1,
        pageSize: params.pagination.pageSize
      },
      search: '',
      filter_data_type: []
    })
    setCreate('')
  }

  const handleEdited = (value: DictionaryFormValue) => {
    fetchDictionaries.current({
      ...params,
      dictionary_type: value?.category || params.dictionary_type,
      pagination: {
        total: 0,
        current: params.pagination.current,
        pageSize: params.pagination.pageSize
      },
      search: params.search,
      filter_data_type: params.filter_data_type,
      filter_status: params.filter_status
    })
    setEditInfo({})
  }

  const handleDeleted = async (id: number) => {
    const { isDelete } = await deleteDictionary(id)
    if (isDelete) {
      fetchDictionaries.current({
        dictionary_type: params.dictionary_type,
        pagination: {
          total: 0,
          current: params.pagination.current,
          pageSize: params.pagination.pageSize
        },
        search: params.search,
        filter_data_type: params.filter_data_type,
        filter_status: params.filter_status
      })
      notification.success({
        message: `${
          dictionaryModalTitle[params.dictionary_type]
        } field is deleted successfully.`
      })
    }
  }

  const handleConfirm = () => {
    fetchDictionaries.current(params)
  }

  const handleClickAdd: MenuProps['onClick'] = (menuItem) => {
    setCreate(menuItem.key)
  }

  const handleClickAction = async (key: string, record: any) => {
    if (['string', 'number'].includes(typeof record?.id)) {
      switch (key) {
        case 'edit': {
          setEditInfo(formatDictionaryFormValue(record))
          break
        }
        case 'delete': {
          await handleDeleted(record.id)
          break
        }
        case 'status': {
          setConfirmInfo(record)
          break
        }
      }
    }
  }

  useEffect(() => {
    fetchDictionaries.current(params)
  }, [])

  return (
    <MainHeader
      headerTitle="Data Dictionary"
      headerDescription="View, create and manage data dictionary"
      stickyHeader
      bgColor="#F7F9FC"
      bodyClassName="flex-1 min-w-0 p-6"
      permissions={[PERMISSIONS.data_dictionary_view]}>
      <DictionaryTable
        loading={loading}
        value={params}
        onChange={handleChange}
        dataSource={dataSource}
        onClickAdd={handleClickAdd}
        onClickAction={handleClickAction}
      />
      <DictionaryModalCreate
        dictionary_type={create}
        onClose={() => setCreate('')}
        onCreated={handleCreated}
      />
      <DictionaryModalEdit
        dictionary_type={params.dictionary_type}
        value={editInfo}
        onClose={() => setEditInfo({})}
        onEdited={handleEdited}
      />
      <DictionaryModalConfirmation
        dictionary_type={params.dictionary_type}
        confirm={confirmInfo}
        onClose={() => setConfirmInfo({})}
        onConfirm={handleConfirm}
      />
    </MainHeader>
  )
}
