import { useCallback, useEffect, useMemo, useState } from 'react'
import { TemplateServices } from 'services/template'

export function useTemplate(props?: { id?: number | string }) {
  const { id } = props || {}
  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState<any>()

  const getTemplate = useCallback(async (id: number | string) => {
    try {
      setLoading(true)
      const res: any = await TemplateServices.getById(id)
      if (!res.data || res?._message) {
        throw new Error(JSON.stringify(res))
      }
      return res.data?.data
    } catch (error) {
      console.log('** RYAN useTemplate.ts 19 error : ', error)
      return null
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!id || template?.template_id === id || template?.id === id) {
      return
    }
    getTemplate(id).then((res) => {
      setTemplate(res)
    })
  }, [getTemplate, id, template?.id, template?.template_id])

  return useMemo(
    () => ({ loading, setLoading, template, setTemplate, getTemplate }),
    [loading, template, getTemplate]
  )
}

export function useTemplateSendTest() {
  return useMemo(() => ({}), [])
}
