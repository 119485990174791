import Icon from '@ant-design/icons'
import { PERMISSIONS } from 'constants/permission'
import { ROLES } from 'constants/role'
import { checkAuth } from 'hooks/useCheckAuth'
// import { useCheckAuth } from 'hooks/useCheckAuth'
import {
  Analytics,
  Campaign,
  Integration,
  Segmentation,
  Settings,
  TemplateBuilder,
  WorkFlow
} from 'icons/V2'
import { ItemMenu } from 'interfaces'
import { Dispatch, SetStateAction, createContext } from 'react'

export const menuRoutes = {
  homepage: '/home',
  analytic: '/analytic',
  dashboard: '/dashboard',
  segment: '/segment',
  segment_dashboard: '/segment/dashboard',
  segment_list: '/segment/list',
  segment_dynamic_list: '/segment/dynamic-list',
  campaign: '/campaign',
  campaign_messaging_insights: '/campaign/messaging-insights',
  persona: '/persona',
  template: '/template-builder',
  workflow: '/workflow',
  insight: '/campaign/insight',
  team_members: '/team-members',
  setting: '/setting',
  setting_notifications: '/setting/notifications',
  setting_clients: '/setting/clients',
  setting_history: '/setting/history',
  setting_data_dictionary: '/setting/dictionary',
  setting_advanced: '/setting/advanced',
  setting_event: '/setting/event',
  setting_tag: '/setting/tag-manager',
  setting_variable: '/setting/variable',
  help: '/help',
  profile: '/profile',
  integration: '/integration'
}

// Settings

// Manage Clients

// History

// Label

// Data Dictionary

// Advance settings

// Get Help

// Avatar & Profiles

export type MenuKeys = keyof typeof menuRoutes

export const menuTopItems: ItemMenu[] = [
  // Dashboard
  // {
  //   type: undefined,
  //   key: menuRoutes.homepage,
  //   label: 'Homepage',
  //   icon: <Icon component={Dashboard} />,
  //   roles: [
  //     ROLES.admin,
  //     ROLES.campaign_manager,
  //     ROLES.campaign_view,
  //     ROLES.read_only,
  //     ROLES.visitor
  //   ],
  //   permissions: [PERMISSIONS.segment_view_segment_details]
  // },
  // Analytics
  {
    type: undefined,
    key: menuRoutes.analytic,
    label: 'Analytics',
    icon: <Icon component={Analytics} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.read_only,
      ROLES.visitor
    ],
    permissions: [PERMISSIONS.segment_view_segment_details]
  },
  // Segmentation
  {
    type: undefined,
    key: menuRoutes.segment,
    label: 'Segmentation',
    icon: <Icon component={Segmentation} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.read_only,
      ROLES.visitor
    ],
    permissions: [PERMISSIONS.segment_view_segment_details],
    children: [
      {
        type: undefined,
        key: menuRoutes.segment_dashboard,
        label: 'Segment',
        roles: [
          ROLES.admin,
          ROLES.campaign_manager,
          ROLES.campaign_view,
          ROLES.read_only,
          ROLES.visitor
        ],
        permissions: [PERMISSIONS.segment_view_segment_details]
      },
      {
        type: undefined,
        key: menuRoutes.segment_list,
        label: 'Static List',
        roles: [
          ROLES.admin,
          ROLES.campaign_manager,
          ROLES.campaign_view,
          ROLES.read_only,
          ROLES.visitor
        ],
        permissions: [PERMISSIONS.segment_view_segment_details]
      },
      {
        type: undefined,
        key: menuRoutes.segment_dynamic_list,
        label: 'Dynamic List',
        roles: [
          ROLES.admin,
          ROLES.campaign_manager,
          ROLES.campaign_view,
          ROLES.read_only,
          ROLES.visitor
        ],
        permissions: [PERMISSIONS.segment_view_segment_details]
      }
    ]
  },
  {
    type: undefined,
    key: menuRoutes.integration,
    label: 'Integration',
    icon: <Icon component={Integration} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.read_only,
      ROLES.visitor
    ]
    // permissions: [PERMISSIONS.segment_view_segment_details]
  },
  // Persona (add later)
  // {
  //   type: undefined,
  //   key: menuRoutes.persona,
  //   label: 'Persona',
  //   icon: <Icon component={Persona} />,
  //   roles: [
  //     ROLES.admin,
  //     ROLES.campaign_manager,
  //     ROLES.campaign_view,
  //     ROLES.read_only,
  //     ROLES.visitor
  //   ],
  //   permissions: ['template_view_table']
  // },
  // Email Template
  {
    type: undefined,
    key: menuRoutes.template,
    label: 'Template Builder',
    icon: <Icon component={TemplateBuilder} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.read_only,
      ROLES.visitor
    ],
    permissions: [PERMISSIONS.template_view_table]
  },
  // Campaign
  {
    type: undefined,
    key: menuRoutes.campaign,
    label: 'Campaign',
    icon: <Icon component={Campaign} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.read_only,
      ROLES.visitor
    ],
    permissions: [PERMISSIONS.campaign_view]
  },
  {
    type: undefined,
    key: menuRoutes.workflow,
    label: 'Workflow',
    icon: <Icon component={WorkFlow} />,
    roles: [
      ROLES.admin,
      ROLES.campaign_manager,
      ROLES.campaign_view,
      ROLES.read_only,
      ROLES.visitor
    ]
  }
  // {
  //   type: undefined,
  //   key: menuRoutes.team_members,
  //   label: 'Team members',
  //   icon: <Icon component={Group} />,
  //   roles: [
  //     ROLES.admin,
  //     ROLES.campaign_manager,
  //     ROLES.campaign_view,
  //     ROLES.read_only,
  //     ROLES.visitor
  //   ],
  //   permissions: []
  // },
].filter(Boolean) as ItemMenu[]

export const menuBottomItems: ItemMenu[] = [
  {
    type: undefined,
    key: menuRoutes.setting,
    label: 'Setting',
    icon: <Icon component={Settings} />,
    children: [
      // {
      //   type: undefined,
      //   key: menuRoutes.setting_clients,
      //   label: 'Manage clients',
      //   roles: [ROLES.admin, ROLES.campaign_manager],
      //   permissions: [PERMISSIONS.manager_client_view]
      // },
      // {
      //   type: undefined,
      //   key: menuRoutes.setting_history,
      //   label: 'History',
      //   roles: [
      //     ROLES.admin,
      //     ROLES.campaign_manager,
      //     ROLES.campaign_view,
      //     ROLES.read_only
      //   ]
      // },
      {
        type: undefined,
        key: menuRoutes.setting_data_dictionary,
        label: 'Data Dictionary',
        permissions: [PERMISSIONS.data_dictionary_view]
      },
      {
        type: undefined,
        key: menuRoutes.setting_event,
        label: 'Custom Events',
        permissions: [PERMISSIONS.event_view_table]
      },
      {
        type: undefined,
        key: menuRoutes.setting_tag,
        label: 'Tag Manager',
        permissions: [PERMISSIONS.tag_view_table]
      },
      {
        type: undefined,
        key: menuRoutes.setting_variable,
        label: 'Variable Manager',
        permissions: [PERMISSIONS.variable_view]
      },
      {
        type: undefined,
        key: menuRoutes.setting_advanced,
        label: 'Advanced',
        permissions: [
          PERMISSIONS.whatsapp_qr_view,
          PERMISSIONS.email_configuration_view,
          PERMISSIONS.frequency_capping_view
        ]
      },
      {
        type: undefined,
        key: menuRoutes.setting_notifications,
        label: 'Notifications',
        permissions: [PERMISSIONS.notification_setting_view]
      }
    ]
  }
  // {
  //   type: undefined,
  //   key: menuRoutes.help,
  //   label: 'Get help',
  //   icon: <Icon component={Support} />
  // }
]

export const getItemsMenuByRolesAndPermissions = (
  items: ItemMenu[],
  auth: Partial<UserAuth['auth']>
): ItemMenu[] | undefined => {
  if (!items) {
    return
  }
  return items
    .map((item) => {
      const isDisabled = !checkAuth(
        {
          sites: item?.sites,
          roles: item?.roles,
          permissions: item?.permissions
        },
        auth
      )
      if (isDisabled) {
        return
      }
      return {
        ...item,
        children: item.children
          ? getItemsMenuByRolesAndPermissions(item.children, auth)
          : undefined
      }
    })
    .filter(Boolean) as ItemMenu[]
}

export type MenuSidebarProps = {
  collapse: boolean
  setCollapse: Dispatch<SetStateAction<boolean>>
  openKeys: string[] | undefined
  setOpenKeys: Dispatch<SetStateAction<string[] | undefined>>
}

export const MenuSidebarContext = createContext<MenuSidebarProps>({
  collapse: false,
  setCollapse: (_value) => {
    /** empty-func */
  },
  openKeys: [],
  setOpenKeys: (_value) => {
    /** empty-func */
  }
})
