import { notification } from 'antd'
import { menuRoutes } from 'components/molecules'
import {
  CampaignFormLandingPage,
  CampaignLandingPageFormProps,
  CampaignLandingPageFormRef
} from 'components/molecules/campaign/CampaignForm'
import { CAMPAIGN_STEP_KEYS } from 'components/molecules/campaign/CampaignForm/CampaignStepHeader'
import { PERMISSIONS } from 'constants/permission'
import { TEMPLATE_TYPE } from 'constants/template'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CampaignServices } from 'services/campaign'

export const CampaignLandingPageCreate = () => {
  const navigate = useNavigate()
  const campaignRef = useRef<CampaignLandingPageFormRef>(null)
  const [, setStep] = useSessionStorage(
    '$campaignStep',
    CAMPAIGN_STEP_KEYS.overviews
  )

  useEffect(() => {
    setStep(CAMPAIGN_STEP_KEYS.overviews)
  }, [setStep])

  const handleSave: NonNullable<CampaignLandingPageFormProps['onSave']> =
    useCallback(
      async (values) => {
        try {
          const response = await CampaignServices.createCampaign({
            name: values?.name || '',
            description: values?.description || '',
            approach: values?.approach || 1,
            client_ids: values?.client_ids || [],
            start_time: values?.start_time || '',
            end_time: values?.end_time || '',
            flow_id: values?.flow_id || null,
            segments_ids: values?.segments_ids || [],
            site_ids: values?.site_ids || [],
            template_id: values?.template_id || null,
            time_zone: values?.time_zone || '',
            type: values?.type || '',
            status: values?.status || '',
            template_type: values?.template_type || ''
          })
          if (response._message && !response.data) {
            throw new Error(JSON.stringify(response))
          }

          if (response.data?.id) {
            campaignRef.current?.modalOnClose()
            notification.success({
              message: 'This campaign was saved successfully'
            })
            navigate(
              `${menuRoutes.campaign}/${TEMPLATE_TYPE.LP_PAGE}/${response.data.id}`
            )
          }
        } catch (error: any) {
          console.log(
            '** ERROR CampaignLandingPageCreate.tsx 46 error : ',
            error
          )
          if (error?.message && typeof error.message === 'string') {
            const errorObj = JSON.parse(error.message)
            if (errorObj?._message) {
              const fields: any[] = []
              const message = errorObj?._message
              if (message.includes('name')) {
                campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
                fields.push({
                  name: 'name',
                  value: values.name,
                  errors: ['Name is existing. Please input another name.']
                })
              }
              const messageArr = message.split(':')
              if (messageArr?.[0].includes('end_time')) {
                campaignRef.current?.onChangeStep(CAMPAIGN_STEP_KEYS.overviews)
                fields.push({
                  name: messageArr?.[0],
                  value: values.end_time,
                  errors: [messageArr?.[1]]
                })
              }
              // Forsure exists overviewsForm
              setTimeout(() => {
                campaignRef.current?.overviewsForm?.setFields(fields)
              }, 250)

              if (!fields.length && message) {
                notification.error({ message })
              }
            }
          }
        }
      },
      [navigate]
    )

  return (
    <CampaignFormLandingPage
      ref={campaignRef}
      template_type={TEMPLATE_TYPE.LP_PAGE}
      onSave={handleSave}
      permissions={[PERMISSIONS.campaign_landing_page_create]}
    />
  )
}
