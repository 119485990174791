import Page404 from './404'
import AnalyticRequestPage from './analytic/AnalyticRequestPage'
import {
  CampaignTemplateCreate,
  CampaignTemplateEdit
} from './campaign/[template_type]'
import CampaignAutomationCreatePage from './campaign/automation/create'
import {
  CampaignLandingPageCreate,
  CampaignLandingPageEdit
} from './campaign/ld_page'
import { INTEGRATION_ITEM_KEYS } from './integration/IntegrationPage'
import LoginPage from './login'
import ResetPasswordRedirect from './password/reset/[token]'
import ResetPassword from './reset-password'
import { RootPage } from './root'
import './segment/[id]'
import { SettingDictionaryPage } from './setting/dictionary'
import { Easter } from 'components/atoms/easter'
import { LoadingFullScreen } from 'components/atoms/loading'
import {
  MenuSidebar,
  MenuSidebarProvider,
  menuRoutes
} from 'components/molecules'
import { ENV, isProduction } from 'constants/env'
import { SEGMENT_TYPE } from 'constants/segment'
import { TEMPLATE_TYPE } from 'constants/template'
import { AppContextComponent } from 'context/App'
import AuthContext, {
  AuthContextComponent,
  useAuthProvider
} from 'context/Auth'
import { NotificationProvider } from 'context/Notifications'
import { Suspense, lazy, useEffect, useRef } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { GlobalStyled } from 'styles'
import { plausibleAPI } from 'utils/plausibleTracking'

const Homepage = lazy(() => import('./homepage'))
const AnalyticPage = lazy(() => import('./analytic'))

const AnalyticDetailPage = lazy(() => import('./analytic/[id]'))
const AnalyticWidgetPage = lazy(() => import('./analytic/[id]/widgets'))
const AnalyticWidgetDetailPage = lazy(
  () => import('./analytic/[id]/widgets/[widget_id]')
)
const SegmentPage = lazy(() => import('./segment'))
const SegmentListPage = lazy(() => import('./segment/indexList'))
const SegmentDynamicListPage = lazy(() => import('./segmentDynamicList'))
const SegmentDetailContactsPage = lazy(() => import('./segmentDetailContacts'))
const TemplateBuilderList = lazy(() => import('./templateBuilderList'))
const TemplateBuilderEmailCreate = lazy(
  () => import('./templateBuilderCreate/emailTemplate')
)
const TemplateBuilderEmailEdit = lazy(
  () => import('./templateBuilderEdit/emailTemplate')
)
const TemplateBuilderLandingPageCreate = lazy(
  () => import('./templateBuilderCreate/landingPage')
)
const TemplateBuilderLandingPageEdit = lazy(
  () => import('./templateBuilderEdit/landingPage')
)
const TemplateBuilderAppPushCreate = lazy(
  () => import('./templateBuilderCreate/appPush')
)
const TemplateBuilderWebPushCreate = lazy(
  () => import('./templateBuilderCreate/webPush')
)
const TemplateBuilderWhatsAppCreate = lazy(
  () => import('./templateBuilderCreate/whatsApp')
)
const TemplateBuilderAppPushEdit = lazy(
  () => import('./templateBuilderEdit/appPush')
)
const TemplateBuilderWebPushEdit = lazy(
  () => import('./templateBuilderEdit/webPush')
)
const TemplateBuilderWhatsAppEdit = lazy(
  () => import('./templateBuilderEdit/whatsApp')
)
const TemplateBuilderTwilioCreate = lazy(
  () => import('./templateBuilderCreate/twilio')
)
const TemplateBuilderTwilioEdit = lazy(
  () => import('./templateBuilderEdit/twilio')
)
const TemplateBuilderZaloCreate = lazy(
  () => import('./templateBuilderCreate/zalo')
)
const TemplateBuilderZaloEdit = lazy(() => import('./templateBuilderEdit/zalo'))

const CampaignPage = lazy(() => import('./campaign'))
const CampaignMessagingInsightsPage = lazy(
  () => import('./campaign/messaging-insights')
)

const CampaignInsightPage = lazy(
  () => import('./campaign/insight/[template_type]/[id]/CampaignInsightPage')
)

const WorkflowPage = lazy(() => import('./workflow'))
const WorkflowInsightPage = lazy(() => import('./workflow/[id]/insight'))
const WorkflowFolderPage = lazy(() => import('./workflow/folder'))
const WorkflowDetail = lazy(() => import('./workflow/[id]'))

// Bottom Menu
const SettingEventPage = lazy(() => import('./setting/event'))
const SettingNotificationsPage = lazy(() => import('./setting/notifications'))
const SettingAdvancedPage = lazy(() => import('./setting/advanced'))
const Clients = lazy(() => import('./setting/clients'))
const History = lazy(() => import('./setting/history'))
const TagManager = lazy(() => import('./setting/tagManager'))
const HelpPage = lazy(() => import('./help'))
const VariableManagerPage = lazy(() => import('./setting/variable'))

// Profile
const ProfilePage = lazy(() => import('./profile'))

// Integration
const IntegrationPage = lazy(() => import('./integration'))
const FacebookConversionsPage = lazy(
  () => import('./integration/FacebookConversions')
)
const FacebookCustomAudiencePage = lazy(
  () => import('./integration/FBCustomAudienceConnector')
)

const SegmentDetail = lazy(() => import('./segment/SegmentDetail'))

const AppContent = (
  <MenuSidebar className="layout-sidebar-fixed" stickyMenu>
    <Suspense fallback={<LoadingFullScreen />}>
      <Routes>
        <Route path={menuRoutes.homepage} element={<Homepage />} />
        <Route path={menuRoutes.profile} element={<ProfilePage />} />
        <Route path={menuRoutes.integration}>
          <Route
            path={INTEGRATION_ITEM_KEYS.fb_conversions_api}
            element={<FacebookConversionsPage />}
          />
          <Route
            path={INTEGRATION_ITEM_KEYS.fb_ad_account}
            element={<FacebookCustomAudiencePage />}
          />
          <Route index element={<IntegrationPage />} />
        </Route>
        <Route path={menuRoutes.analytic}>
          <Route path=":id">
            <Route path="widgets">
              <Route path=":widget_id" element={<AnalyticWidgetDetailPage />} />
              <Route index element={<AnalyticWidgetPage />} />
            </Route>
            <Route index element={<AnalyticDetailPage />} />
          </Route>
          <Route index element={<AnalyticPage />} />
        </Route>
        <Route path="template-builder">
          <Route
            path="new-email-template"
            element={<TemplateBuilderEmailCreate />}
          />
          <Route
            path="new-app-push"
            element={<TemplateBuilderAppPushCreate />}
          />
          <Route
            path="new-web-push"
            element={<TemplateBuilderWebPushCreate />}
          />
          <Route
            path="new-whats-app"
            element={<TemplateBuilderWhatsAppCreate />}
          />
          <Route
            path="new-landing-page"
            element={<TemplateBuilderLandingPageCreate />}
          />
          <Route
            path={`new-${TEMPLATE_TYPE.TWILIO}`}
            element={<TemplateBuilderTwilioCreate />}
          />
          <Route
            path={`new-${TEMPLATE_TYPE.ZALO}`}
            element={<TemplateBuilderZaloCreate />}
          />
          <Route
            path="landing-page/:id"
            element={<TemplateBuilderLandingPageEdit />}
          />
          <Route
            path="landing-page/insight/:id"
            element={<TemplateBuilderLandingPageEdit />}
          />
          <Route path="email/:id" element={<TemplateBuilderEmailEdit />} />
          <Route path="app-push/:id" element={<TemplateBuilderAppPushEdit />} />
          <Route path="web-push/:id" element={<TemplateBuilderWebPushEdit />} />
          <Route
            path="whats-app/:id"
            element={<TemplateBuilderWhatsAppEdit />}
          />
          <Route
            path={`${TEMPLATE_TYPE.TWILIO}/:id`}
            element={<TemplateBuilderTwilioEdit />}
          />
          <Route
            path={`${TEMPLATE_TYPE.ZALO}/:id`}
            element={<TemplateBuilderZaloEdit />}
          />
          <Route index element={<TemplateBuilderList />} />
        </Route>
        <Route path={menuRoutes.segment}>
          <Route index element={<SegmentPage />} />
          <Route path="create">
            <Route path={SEGMENT_TYPE.EVENT} element={<SegmentDetail />} />
            <Route index element={<SegmentDetail />} />
          </Route>
          <Route path=":id">
            <Route path="contact" element={<SegmentDetailContactsPage />} />
            <Route index element={<SegmentDetail />} />
          </Route>
          <Route path="dashboard" element={<SegmentPage />} />
          <Route path="list" element={<SegmentListPage />} />
          <Route path="dynamic-list" element={<SegmentDynamicListPage />} />
        </Route>
        <Route path={menuRoutes.campaign}>
          <Route
            path="messaging-insights"
            element={<CampaignMessagingInsightsPage />}
          />
          <Route path={TEMPLATE_TYPE.LP_PAGE}>
            <Route path="create" element={<CampaignLandingPageCreate />} />
            <Route path=":id" element={<CampaignLandingPageEdit />} />
          </Route>
          <Route path=":template_type">
            <Route path="create" element={<CampaignTemplateCreate />} />
            <Route path=":id" element={<CampaignTemplateEdit />} />
          </Route>
          <Route path="automation">
            <Route path="create" element={<CampaignAutomationCreatePage />} />
            <Route path=":id" element={<CampaignAutomationCreatePage />} />
          </Route>
          <Route path="insight">
            <Route path=":template_type">
              <Route path=":id" element={<CampaignInsightPage />} />
            </Route>
          </Route>
          <Route index element={<CampaignPage />} />
        </Route>

        <Route path={menuRoutes.workflow}>
          <Route index element={<WorkflowPage />} />
          <Route path="new-workflow" element={<WorkflowDetail />} />
          <Route path=":id">
            <Route index element={<WorkflowDetail />} />
            <Route path="insight" element={<WorkflowInsightPage />} />
          </Route>
          <Route path="folder">
            <Route index element={<WorkflowFolderPage />} />
          </Route>
        </Route>
        <Route path={menuRoutes.setting_clients} element={<Clients />} />
        <Route path={menuRoutes.setting_history} element={<History />} />
        <Route path={menuRoutes.setting_tag} element={<TagManager />} />
        <Route path={menuRoutes.setting_event} element={<SettingEventPage />} />
        <Route
          path={menuRoutes.setting_notifications}
          element={<SettingNotificationsPage />}
        />
        <Route
          path={menuRoutes.setting_variable}
          element={<VariableManagerPage />}
        />
        <Route
          path={menuRoutes.setting_data_dictionary}
          element={<SettingDictionaryPage />}
        />
        <Route
          path={menuRoutes.setting_advanced}
          element={<SettingAdvancedPage />}
        />
        <Route path={menuRoutes.help} element={<HelpPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  </MenuSidebar>
)

const App = () => {
  const auth = useAuthProvider()
  const location = useLocation()
  const debounce = useRef<NodeJS.Timeout>()

  useEffect(() => {
    document.body.classList.add(ENV.reactEnv)
  }, [])

  useEffect(() => {
    if (debounce.current) clearTimeout(debounce.current)
    debounce.current = setTimeout(() => {
      plausibleAPI.trackPageview(
        {
          trackLocalhost: !isProduction
        },
        {
          props: {
            type: 'pageView',
            uid: (auth.userAuth?.id || '') as string,
            un: (auth.userAuth?.name || '') as string,
            ue: (auth.userAuth?.email || '') as string
          }
        }
      )
    }, 250)
  }, [
    auth.userAuth?.email,
    auth.userAuth?.id,
    auth.userAuth?.name,
    location.pathname
  ])

  return (
    <AuthContext.Provider value={auth}>
      <GlobalStyled />
      <NotificationProvider>
        <MenuSidebarProvider>
          <Easter />
          <Routes>
            <Route path="/">
              <Route index element={<RootPage />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route
                path="password/reset/:token"
                element={<ResetPasswordRedirect />}
              />
              <Route
                path="analytic/:id/request"
                element={<AnalyticRequestPage />}
              />
              <Route
                path="*"
                element={
                  <AuthContextComponent>
                    <AppContextComponent>{AppContent}</AppContextComponent>
                  </AuthContextComponent>
                }
              />
            </Route>
          </Routes>
        </MenuSidebarProvider>
      </NotificationProvider>
    </AuthContext.Provider>
  )
}

export default App
