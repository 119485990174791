import { StyledDrawer, StyledDrawerExtra } from './DrawerPreviewTemplate.styled'
import Icon, { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { DrawerProps } from 'antd'
import { TemplatePreview } from 'components/atoms/TemplatePreview'
import { TEMPLATE_TYPE } from 'constants/template'
import { Close, Edit } from 'icons'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { getTemplateRoute } from 'utils/template'

export type DrawerPreviewTemplateProps = Pick<
  DrawerProps,
  'open' | 'onClose' | 'getContainer'
> & {
  data?: any
}

export const DrawerPreviewTemplate = (props: DrawerPreviewTemplateProps) => {
  const { open: openProps, onClose, getContainer, data } = props
  const [fullWidth, setFullWidth] = useState(false)
  const [open, setOpen] = useState(!!openProps || !!data?.id)
  const navigate = useNavigate()

  const IconFullscreenComp = useMemo(() => {
    if (fullWidth) {
      return ZoomOutOutlined
    }
    return ZoomInOutlined
  }, [fullWidth])

  const handleEdit = useCallback(() => {
    const id = data?.template_id || data?.template?.id
    navigate(getTemplateRoute(id, data?.template?.type))
  }, [data?.template_id, data?.template?.id, data?.template?.type, navigate])

  useEffect(() => {
    setOpen(!!openProps || !!data?.id)
  }, [openProps, data?.id])

  useEffect(() => {
    return () => {
      setFullWidth(false)
    }
  }, [])

  const width = useMemo(() => {
    if (fullWidth) return '100%'

    if (
      data?.template?.type &&
      [TEMPLATE_TYPE.EMAIL, TEMPLATE_TYPE.LP_PAGE].includes(
        data?.template?.type
      )
    ) {
      return 868
    }

    return 648
  }, [data?.template?.type, fullWidth])

  return (
    <StyledDrawer
      className={data?.template?.type}
      width={width}
      title={data?.template?.name}
      open={open}
      mask={false}
      closeIcon={null}
      onClose={onClose}
      getContainer={getContainer}
      extra={
        <StyledDrawerExtra>
          <Icon component={Edit} onClick={handleEdit} />
          <IconFullscreenComp onClick={() => setFullWidth(!fullWidth)} />
          <Icon component={Close} onClick={onClose} />
        </StyledDrawerExtra>
      }>
      <TemplatePreview type={data?.template?.type} previewInfo={data} />
    </StyledDrawer>
  )
}
