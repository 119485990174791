export const PERMISSIONS = {
  //#region PERMISSIONS SEGMENT
  segment_create: 'segment_create',
  segment_edit: 'segment_edit',
  segment_view_segment_details: 'segment_view_segment_details',
  segment_delete: 'segment_delete',
  segment_search: 'segment_search',
  segment_filter: 'segment_filter',
  segment_export: 'segment_export',
  segment_import: 'segment_import',
  segment_static_list_create: 'segment_static_list_create',
  segment_static_list_edit: 'segment_static_list_edit',
  segment_static_list_delete: 'segment_static_list_delete',
  segment_dynamic_list_create: 'segment_dynamic_list_create',
  segment_dynamic_list_edit: 'segment_dynamic_list_edit',
  //#endregion

  //#region PERMISSIONS TEMPLATE
  template_view_table: 'template_view_table',
  template_email_create: 'template_email_create',
  template_email_edit: 'template_email_edit',
  template_landing_page_create: 'template_landing_page_create',
  template_landing_page_edit: 'template_landing_page_edit',
  template_delete: 'template_delete',
  template_archived: 'template_archived',
  template_landing_page_publish: 'template_landing_page_publish',
  template_email_release: 'template_email_release',
  template_email_preview: 'template_email_preview',
  template_app_push_create: 'template_app_push_create',
  template_app_push_edit: 'template_app_push_edit',
  template_app_push_delete: 'template_app_push_delete',
  template_web_push_create: 'template_web_push_create',
  template_web_push_edit: 'template_web_push_edit',
  template_web_push_delete: 'template_web_push_delete',
  template_duplicate: 'template_duplicate',
  template_whatsapp_create: 'template_whatsapp_create',
  template_whatsapp_edit: 'template_whatsapp_edit',
  template_whatsapp_delete: 'template_whatsapp_delete',
  template_search: 'template_search',
  template_filter: 'template_filter',
  //#endregion

  //#region PERMISSIONS CAMPAIGN
  campaign_create_blast: 'campaign_create_blast',
  campaign_create_trigger: 'campaign_create_trigger',
  campaign_view: 'campaign_view',
  campaign_edit: 'campaign_edit',
  campaign_search: 'campaign_search',
  campaign_view_insights: 'campaign_view_insights',
  campaign_duplicate: 'campaign_duplicate',
  campaign_start: 'campaign_start',
  campaign_cancel: 'campaign_cancel',
  campaign_delete: 'campaign_delete',
  campaign_restore_archived: 'campaign_restore_archived',
  campaign_app_push_create: 'campaign_app_push_create',
  campaign_app_push_edit: 'campaign_app_push_edit',
  campaign_web_push_create: 'campaign_web_push_create',
  campaign_web_push_edit: 'campaign_web_push_edit',
  campaign_whatsapp_create: 'campaign_whatsapp_create',
  campaign_whatsapp_edit: 'campaign_whatsapp_edit',
  campaign_landing_page_create: 'campaign_landing_page_create',
  campaign_landing_page_edit: 'campaign_landing_page_edit',
  campaign_email_create: 'campaign_email_create',
  campaign_email_edit: 'campaign_email_edit',
  campaign_send_test_email: 'campaign_send_test_email',
  //#endregion

  //#region PERMISSIONS SETTINGS
  data_dictionary_view: 'data_dictionary_view',
  data_dictionary_create: 'data_dictionary_create',
  data_dictionary_edit: 'data_dictionary_edit',
  data_dictionary_delete: 'data_dictionary_delete',
  data_dictionary_search: 'data_dictionary_search',
  event_view_table: 'event_view_table',
  event_create: 'event_create',
  event_edit: 'event_edit',
  event_delete: 'event_delete',
  event_filter: 'event_filter',
  event_search: 'event_search',
  tag_view_table: 'tag_view_table',
  tag_create: 'tag_create',
  tag_edit: 'tag_edit',
  tag_delete: 'tag_delete',
  tag_search: 'tag_search',
  tag_filter: 'tag_filter',
  variable_view: 'variable_view',
  variable_create: 'variable_create',
  variable_delete: 'variable_delete',
  variable_edit: 'variable_edit',
  variable_search: 'variable_search',
  frequency_capping_edit: 'frequency_capping_edit',
  frequency_capping_view: 'frequency_capping_view',
  email_configuration_edit: 'email_configuration_edit',
  email_configuration_view: 'email_configuration_view',
  whatsapp_qr_create: 'whatsapp_qr_create',
  whatsapp_qr_edit: 'whatsapp_qr_edit',
  whatsapp_qr_delete: 'whatsapp_qr_delete',
  whatsapp_qr_view: 'whatsapp_qr_view',
  history_view: 'history_view',
  manager_client_view: 'manager_client_view',
  manager_client_create: 'manager_client_create',
  manager_client_update: 'manager_client_update',
  manager_client_delete: 'manager_client_delete',
  notification_setting_view: 'notification_setting_view',
  notification_setting_create: 'notification_setting_create',
  notification_setting_edit: 'notification_setting_edit',
  //#endregion

  //#region PERMISSIONS ANALYTICS
  analytics_edit_filter_time_range: 'analytics_edit_filter_time_range',
  analytics_view: 'analytics_view',
  analytics_user_view: 'analytics_user_view',
  analytics_members_view: 'analytics_members_view',
  analytics_cr_view: 'analytics_cr_view',
  analytics_mql_view: 'analytics_mql_view',
  analytics_sql_view: 'analytics_sql_view',
  analytics_sl_view: 'analytics_sl_view',
  analytics_cl_view: 'analytics_cl_view',
  analytics_revenue_view: 'analytics_revenue_view',
  analytics_mau_view: 'analytics_mau_view',
  analytics_dau_view: 'analytics_dau_view',
  analytics_comparison_chart_view: 'analytics_comparison_chart_view',
  analytics_session_chart_view: 'analytics_session_chart_view',
  analytics_behavior_chart_view: 'analytics_behavior_chart_view',
  analytics_demographic_chart_view: 'analytics_demographic_chart_view',
  analytics_gender_chart_view: 'analytics_gender_chart_view',
  analytics_caterory_chart_view: 'analytics_caterory_chart_view',
  analytics_product_chart_view: 'analytics_product_chart_view',
  analytics_funnel_chart_view: 'analytics_funnel_chart_view',
  analytics_device_chart_view: 'analytics_device_chart_view',
  analytics_sub_segment_chart_view: 'analytics_sub_segment_chart_view',
  analytics_segment_user_chart_view: 'analytics_segment_user_chart_view',
  //#endregion

  //#region PERMISSIONS WORKFLOW
  workflow_view_dashboard: 'workflow_view_dashboard',
  workflow_view_insight: 'workflow_view_insight',
  workflow_search: 'workflow_search',
  workflow_start: 'workflow_start',
  workflow_stop: 'workflow_stop',
  workflow_duplicate: 'workflow_duplicate',
  workflow_archive: 'workflow_archive',
  workflow_create: 'workflow_create',
  workflow_update: 'workflow_update',
  //#endregion

  //#region PERMISSIONS INTEGRATION
  integration_view: 'integration_view',
  fb_conversion_add_des: 'fb_conversion_add_des',
  fb_conversion_add_pixelID: 'fb_conversion_add_pixelID',
  fb_conversion_update_info: 'fb_conversion_update_info',
  fb_conversion_delete_info: 'fb_conversion_delete_info',
  fb_audience_add_account: 'fb_audience_add_account',
  fb_audience_update_info: 'fb_audience_update_info',
  fb_audience_delete_info: 'fb_audience_delete_info',
  fb_sync_segment: 'fb_sync_segment',
  fb_sync_create_audience: 'fb_sync_create_audience'
  //#endregion
}
