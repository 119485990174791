import { TemplatePreview } from '../TemplatePreview'
import PhoneInput from 'antd-phone-input'
import Form, { FormProps, Rule } from 'antd/lib/form'
import Input from 'antd/lib/input'
import InputNumber from 'antd/lib/input-number'
import Skeleton from 'antd/lib/skeleton'
import Typography from 'antd/lib/typography'
import { TEMPLATE_TYPE } from 'constants/template'
import { FC, useMemo } from 'react'

const TEMPLATEFORMSENDTEST: Record<string, Rule[]> = {
  phone_number: [
    { required: true, message: 'The field phone is required' },
    {
      validator: (_, { valid }) => {
        if (valid()) return Promise.resolve()
        return Promise.reject('Invalid phone number')
      }
    }
  ]
}

export type TemplateFormSendTestProps = Pick<
  FormProps,
  | 'form'
  | 'name'
  | 'disabled'
  | 'onValuesChange'
  | 'onFinish'
  | 'onFinishFailed'
> & {
  loading?: boolean
  type?: string
  template?: any
}

export const TemplateFormSendTest: FC<TemplateFormSendTestProps> = (props) => {
  const { loading, type, template, form: formProps, ...restProps } = props
  const [form] = Form.useForm(formProps)
  const variablesForm = Form.useWatch('variables', form)

  const formItemVariables = useMemo(() => {
    if (
      !template?.template?.body_json?.listParams ||
      !template?.template?.body_json?.listParams.length
    ) {
      return null
    }
    let i = 1
    return template?.template?.body_json?.listParams?.map(
      (item: any, index: number) => {
        const key = `${item?.name}-${index}`
        const name = ['variables', item?.name]

        if (item?.type === 'STRING') {
          return (
            <Form.Item
              key={key}
              name={name}
              label={`Variable ${i++} (${item?.type})`}>
              <Input
                placeholder={item?.name}
                maxLength={item?.maxLength}
                minLength={item?.minLength}
              />
            </Form.Item>
          )
        }
        if (item?.type === 'NUMBER') {
          return (
            <Form.Item
              key={key}
              name={name}
              label={`Variable ${i++} (${item?.type})`}>
              <InputNumber
                placeholder={item?.name}
                maxLength={item?.maxLength}
                minLength={item?.minLength}
              />
            </Form.Item>
          )
        }

        return null
      }
    )
  }, [template?.template?.body_json?.listParams])

  const twilioFormItemVariables = useMemo(() => {
    if (!template?.body_variable || !template?.body_variable?.length) {
      return null
    }
    let i = 1
    return template.body_variable.map((variable: any, index: number) => {
      if (typeof variable === 'string') {
        return (
          <Form.Item
            key={`${variable}-${index}`}
            name={['variables', variable]}
            label={`Variable ${i++} `}>
            <Input placeholder={variable} />
          </Form.Item>
        )
      }

      return null
    })
  }, [template?.body_variable])

  const whatsappFormItemVariables = useMemo(() => {
    if (
      !template?.header_variable?.length &&
      !template?.body_variable?.length
    ) {
      return null
    }
    const header_variable = template?.header_variable || []
    const body_variable = template?.body_variable || []
    const variables = header_variable
      .filter((key: string) => !body_variable.includes(key))
      .concat(body_variable)

    let i = 1
    return variables.map((variable: string, index: number) => {
      if (typeof variable === 'string') {
        return (
          <Form.Item
            key={`${variable}-${index}`}
            name={['variables', variable]}
            label={`Variable ${i++} `}>
            <Input placeholder={variable} />
          </Form.Item>
        )
      }
      return null
    })
  }, [template])

  // body_html for SMS/TWILIO/ZALO
  const body_html = useMemo(() => {
    let body_html = template?.template?.body_html || ''
    Object.keys(variablesForm || {}).forEach((key) => {
      body_html = body_html?.replaceAll(
        `{{.${key}}}`,
        variablesForm[key as keyof typeof variablesForm] || `{{.${key}}}`
      )
    })
    return body_html
  }, [template?.template?.body_html, variablesForm])
  // body_example for WHATSAPP
  const body_example = useMemo(() => {
    if (!template?.body_variable || !template.body_variable?.length) {
      return []
    }
    return template.body_variable.map((key: string, index: number) => {
      return (
        variablesForm?.[key as keyof typeof variablesForm] ||
        template?.body_example?.[index] ||
        ''
      )
    })
  }, [template, variablesForm])
  const header_example = useMemo(() => {
    if (!template?.header_variable || !template.header_variable?.length) {
      return []
    }
    return template.header_variable.map((key: string, index: number) => {
      return (
        variablesForm?.[key as keyof typeof variablesForm] ||
        template?.header_example?.[index] ||
        ''
      )
    })
  }, [template, variablesForm])

  return (
    <Form {...restProps} form={form}>
      <Skeleton
        active
        loading={loading}
        title={{ className: 'hidden' }}
        paragraph={{ rows: 7 }}>
        <Form.Item name="template_id" hidden>
          <Input />
        </Form.Item>

        {type === TEMPLATE_TYPE.WHATSAPP && (
          <>
            <Typography.Text
              italic={true}
              style={{ display: 'block', fontSize: 12, marginBottom: 10 }}>
              We&#39;ll send a test to your phone number. Please ensure
              you&#39;ve already published this template to receive the latest
              version.
            </Typography.Text>
            <Typography.Text className="pb-[14px] block">
              Template name: <strong>{template?.template?.name}</strong>
            </Typography.Text>
            <Form.Item
              name="phone_number"
              rules={TEMPLATEFORMSENDTEST.phone_number}>
              <PhoneInput
                country="VN"
                preferredCountries={['vn']}
                enableSearch
              />
            </Form.Item>
            {whatsappFormItemVariables}
          </>
        )}
        {type === TEMPLATE_TYPE.VNPT && (
          <>
            <Typography.Text
              italic={true}
              style={{ display: 'block', fontSize: 12, marginBottom: 10 }}>
              We&#39;ll send a test to your phone number. Please ensure
              you&#39;ve already published this template to receive the latest
              version.
            </Typography.Text>
            <Typography.Text className="pb-[14px] block">
              Template name: <strong>{template?.template?.name}</strong>
            </Typography.Text>
            <Form.Item
              name="phone_number"
              rules={TEMPLATEFORMSENDTEST.phone_number}>
              <PhoneInput
                country="VN"
                preferredCountries={['vn']}
                enableSearch
              />
            </Form.Item>
            {formItemVariables}
          </>
        )}
        {type === TEMPLATE_TYPE.TWILIO && (
          <>
            <Typography.Text
              italic={true}
              style={{ display: 'block', fontSize: 12, marginBottom: 10 }}>
              We&#39;ll send a test to your phone number. Please ensure
              you&#39;ve already published this template to receive the latest
              version.
            </Typography.Text>
            <Typography.Text className="pb-[14px] block">
              Template name: <strong>{template?.template?.name}</strong>
            </Typography.Text>
            <Form.Item
              name="phone_number"
              rules={TEMPLATEFORMSENDTEST.phone_number}>
              <PhoneInput
                country="VN"
                preferredCountries={['vn']}
                enableSearch
              />
            </Form.Item>
            {twilioFormItemVariables}
          </>
        )}
        {type === TEMPLATE_TYPE.ZALO && (
          <>
            <Typography.Text
              italic={true}
              style={{ display: 'block', fontSize: 12, marginBottom: 10 }}>
              We&#39;ll send a test to your phone number. Please ensure
              you&#39;ve already published this template to receive the latest
              version.
            </Typography.Text>
            <Typography.Text className="pb-[14px] block">
              Template name: <strong>{template?.template?.name}</strong>
            </Typography.Text>
            <Form.Item
              name="phone_number"
              rules={TEMPLATEFORMSENDTEST.phone_number}>
              <PhoneInput
                country="VN"
                preferredCountries={['vn']}
                enableSearch
              />
            </Form.Item>
            {formItemVariables}
          </>
        )}

        <TemplatePreview
          type={type}
          previewInfo={{
            ...template,
            header_example,
            body_example,
            template: { ...template?.template, body_html }
          }}
        />
      </Skeleton>
    </Form>
  )
}
